import React from "react";
import {Carousel} from "@material-tailwind/react";
import Feedback from "../feedback/Feedback";
import {FeatureNoRegistration} from "./FeatureNoRegistration";
import {FeaturePalette} from "./FeaturePalette";
import {FeatureEditing} from "./FeatureEditing";
import {FeatureData} from "./FeatureData";
import {FeaturePlanning} from "./FeaturePlanning";
import {FeatureAutosaving} from "./FeatureAutosaving";
import {FeatureShare} from "./FeatureShare";

export function CarouselFeatures() {
    return (
        <div className="flex flex-col">
            <Carousel autoplay>
                <FeaturePalette/>
                <FeatureEditing/>
                <FeatureData/>
                <div className="flex flex-col justify-between">
                    <FeatureNoRegistration/>
                    <FeaturePlanning/>
                    <FeatureAutosaving/>
                </div>
                <FeatureShare/>
            </Carousel>
            <div className="h-10"/>
            <Feedback/>
        </div>
    );
}