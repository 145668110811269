import Picture from "../../../../../assets/cards/NoRegistration.png";
import {getString} from "../../../../../translations/Strings";

export function FeatureNoRegistration() {
    return (
        <div className="container relative card-style card-border">
            <img src={Picture} alt="card no registration feature" className="rounded-[20px]"/>
            <div className="absolute card-content-offset-bottom-left">
                <FeatureNoRegistrationTitle title={getString("landing_features_no_registration_title")}/>
                <p className="card-description-horizontal md:w-2/3">
                    {getString("landing_features_no_registration_description")}
                </p>
            </div>
        </div>
    )
}

function FeatureNoRegistrationTitle(props) {
    let lines = props.title.split("\n");

    return (
        <div className="whitespace-pre pb-2 md:pb-8 card-title-horizontal">
            <p className="pb-2">
                {lines[0]}
            </p>
            <div className="md:pt-2 pb-1 md:pb-4 pl-1 pr-1 md:pl-4 md:pr-4 rounded-[8px] md:rounded-[20px] bg-white text-black w-fit">
                {lines[1]}
            </div>
            <p>
                {lines[2]}
            </p>
        </div>
    )
}