import React from "react";
import ParcaeLogoDark from '../assets/icons/logo_dark.svg'

const LogoCircle = () => {
    return (
        <div className="flex size-7 sm:size-10">
            <img src={ParcaeLogoDark} alt=''/>
        </div>
    )
}

export default LogoCircle