export function FeedbackResultMessage(props) {
    const errorModifier = () => {
        return props.isError ? "text-error" : "text-success";
    }
    const className = () => {
        return `px-4 py-3 text-[12px] ${errorModifier()}`
    }
    return (
        <div>
            {props.show ? <p className={className()}>{props.children}</p> : null}
        </div>
    )
}