import React from "react";
import {getString} from "../../../../../translations/Strings";

const IntroText = () => {
    return (
        <div className="text-white">
            <h1 className="pb-8 text-8 text-center md:text-10 md:text-left font-medium leading-none">{getString("landing_intro_title")}</h1>
            <p className="font-light pb-12 text-2 text-center md:text-4 md:text-left">{getString("landing_intro_description")}</p>
        </div>
    )
}

export default IntroText