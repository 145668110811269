import React from "react";
import VideoPlayer from "./VideoPlayer";
import Pixel8Frame from "../assets/pixel-8-hazel-full.png"
import GooglePlayBadge from "./GooglePlayBadge";

const PhoneFrame = () => {
    return (
        <div className="container max-w-[360px] md:w-[384px] h-fit relative">
            <div className="mx-4/100 mt-5/100 absolute">
                <VideoPlayer/>
            </div>
            <div className="z-10 absolute flex flex-col">
                <img className="pb-7" alt="pixel 8 frame" src={Pixel8Frame} />
                <div className="contents sm:hidden relative">
                    <GooglePlayBadge/>
                </div>
            </div>
        </div>
    );
}

export default PhoneFrame