import Picture from "../../../../../assets/cards/Data.png";
import {getString} from "../../../../../translations/Strings";

export function FeatureData() {
    return (
        <div className="container relative card-style">
            <img src={Picture} alt="card feature data" className="rounded-[20px]"/>
            <div className="absolute top-8 w-full">
                <div className="pl-5 pr-5 text-center">
                    <p className="text-7 leading-none pb-4 whitespace-pre">
                        {getString("landing_features_data_title")}
                    </p>
                    <p className="font-light">
                        {getString("landing_features_data_description")}
                    </p>
                </div>
            </div>
        </div>
    )
}