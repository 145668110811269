import {useState} from "react"
import {getString} from "../../translations/Strings";

class InputProps {
    id: string;
    type: string;
    value: string;
    onChange: any;
    className: string;
    label: string;
}

function checkEmailError(value: string): boolean {
    return !value.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/);
}


export function Input(props: InputProps) {
    const [isFocused, setIsFocused] = useState(false)
    const [isError, setIsError] = useState(false)
    const errorModifier = () => {
        return isError ? "transition-all ease-in-out delay-150 border-error" : "transition-all ease-in-out delay-150";
    }
    const className = () =>  {
        return `transition-all ease-in-out delay-150 text-input text-white rounded-[20px] text-[16px] px-4 bg-[#111111] border-[1px] ring-0 focus:border-[2px] focus:outline-none outline-none block w-full p-4 ${errorModifier()}`
    }
    return (
        <div className="relative w-full group">
            <Label id={props.id} isFocused={isFocused} isError={isError}>{props.label}</Label>
            <input
                type={props.type}
                id={props.id}
                value={props.value}
                onChange={
                    (e) => {
                        props.onChange(e);
                        setIsError(false);
                    }
                }
                onFocus={
                    (e) => {
                        setIsFocused(true)
                    }
                }
                onBlur={
                    (e) => {
                        setIsFocused(false);
                        setIsError(checkEmailError(e.target.value));
                    }
                }
                className={className()}
            />
            {isError && <p className="ml-4 mt-2 text-error text-1">
                {getString("landing_feedback_email_error_invalid")}
            </p>}
        </div>
    )
}

export function TextArea(props) {
    const errorModifier = () => {
        return props.error !== "" ? "transition-all ease-in-out delay-150 border-error" : "transition-all ease-in-out delay-150"
    }
    const className = () => {
        return `transition-all ease-in-out delay-150 rounded-[20px] text-[16px] px-4 bg-[#111111] border-[1px] focus:border-[2px] focus:outline-none outline-none block w-full p-4 ${errorModifier()}`
    }
    return (
        <div className="relative w-full group">
            <Label id={props.id} isError={props.error !== ""}>{props.label}</Label>
            <textarea
                rows={4}
                id={props.id}
                value={props.value}
                onChange={
                    (e) => {
                        props.onChange(e);
                    }
                }
                className={className()}
            />
            {
                props.error !== "" && <p className="ml-4 mt-2 text-error text-1">
                    <p>{props.error}</p>
                </p>
            }
        </div>
    )
}

export function Label(props) {
    const errorModifier = () => {
        return props.isError ? "text-error" : ""
    }
    const className = () => {
        if (props.value === "" && !props.isFocused) {
            return "transition-all ease-in-out font-normal group-focus-within:font-medium delay-150 bg-[#111111] leading-[24px] text-[16px] text-[#A0A0A0] relative px-1 top-[40px] left-[20px] w-auto"
        } else {
            return `transition-all ease-in-out font-normal group-focus-within:font-medium delay-150 bg-[#111111] text-[12px] relative px-1 top-[10px] left-[20px] w-auto ${errorModifier()}`
        }
    }
    return (
        <label
            htmlFor={props.id}
            className={className()}
        >
            {props.children}
        </label>
    )
}