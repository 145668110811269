import React from 'react'
import Loader from '../assets/screen-20241130-144442~2.mp4'

function VideoPlayer() {
    return (
        <video autoPlay loop muted>
            <source src={Loader} type="video/webm"/>
            Your browser does not support the video tag.
        </video>
    );
}

export default VideoPlayer