import React from "react";
import IntroText from "./IntroText";
import PhoneFrame from "../../../../PhoneFrame";
import GooglePlayBadge from "../../../../GooglePlayBadge";

const Intro = () => {
    return (
        <div className="w-full h-[1200px] md:h-[900px] flex flex-col">
            <div class="contents md:hidden">
                <div className="flex flex-col items-center self-start">
                    <IntroText/>
                    <PhoneFrame/>
                </div>
            </div>
            <div class="hidden md:contents">
                <div className='flex items-top justify-between self-center'>
                    <div className="flex flex-col w-1/2">
                        <IntroText/>
                        <div className="self-start">
                            <GooglePlayBadge/>
                        </div>
                    </div>
                    <PhoneFrame/>
                </div>
            </div>
        </div>
    )
}

export default Intro