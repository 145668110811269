import Picture from "../../../../../assets/cards/Share.png";
import {getString} from "../../../../../translations/Strings";

export function FeatureShare() {
    return (
        <div className="container relative card-style card-border">
            <img src={Picture} alt="card feature share" className="rounded-[20px]"/>
            <div className="absolute top-8">
                <div className="pl-5 pr-5 text-center">
                    <p className="card-title-vertical">
                        {getString("landing_features_share_title")}
                    </p>
                    <p className="card-description-vertical">
                        {getString("landing_features_share_description")}
                    </p>
                </div>
            </div>
        </div>
    )
}