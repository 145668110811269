import Topbar from "./sections/navigation/Topbar";
import Intro from "./sections/intro/Intro";
import Features from "./sections/Features";
import {useRef} from "react";

const Main = () => {
    const bottomRef = useRef("bottomRef");
    const scrollToBottom = () => {
        bottomRef.current.scrollIntoView({ behavior: "smooth" });
        function focusEmail() {
            document.getElementById("email").focus();
        }
        setTimeout(focusEmail, 600)
    };

    return (
        <div className='w-full flex flex-col overscroll-none overflow-hidden'>
            <div className="px-6 sm:px-10 lg:px-0 w-full lg:w-2/3 self-center">
                <div className='w-full flex flex-col font-sans'>
                    <Topbar scrollToBottom={scrollToBottom}/>
                    <div className="h-11"/>
                    <Intro/>
                    <div className="w-full"><Features/></div>
                    <div ref={bottomRef} className="size-0"/>
                </div>
            </div>
        </div>
    );
}

export default Main;