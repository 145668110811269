import LogoCircle from "../../LogoCircle";


export function AccountDeletion() {
    return (
        <div className="w-full flex flex-col">
            <div className="page flex flex-col w-full p-5 md:w-2/3 md:p-0 self-center">
                <div className="header">
                    <div className="w-full container flex justify-between items-center">
                        <p className="pb-6 text-4 sm:text-10 font-bold tracking-tight">Deleting your Parcae account</p>
                        <LogoCircle/>
                    </div>
                </div>
                <div className="translations-content-container pb-8">
                    <div className="container">
                        <h1 className="text-4">
                            <strong>We're sorry to see you go! Follow these steps to delete your account.</strong>
                        </h1>
                        <p class="note"><strong>Note:</strong> Make sure you are <strong>logged in</strong> to your account before starting.</p>


                        <h2 className="text-3">Steps to Delete Your Account:</h2>

                        <ol>
                            <li>
                                <strong>Cancel Subscription (if applicable):</strong>
                                <ul>
                                    <li>  ●   If you <strong>don’t have an active subscription</strong>, you can proceed directly to Step 2.</li>
                                    <li>  ●   If you do have a subscription, <strong>cancel it first</strong></li>
                                    <li>  ●   Wait up to <strong>15 minutes after your active subscription expiration</strong></li>
                                </ul>
                            </li>


                            <li>
                                <strong>Delete Your Account:</strong>
                                <ul>
                                    <li>  ●   Open the Parcae app and go to the <strong>Main Screen</strong>.</li>
                                    <li>  ●   Tap your <strong>Avatar Icon</strong> at the top.</li>
                                    <li>  ●   In the <strong>Navigation Drawer</strong>, select <strong>More ⋮&gt; Delete Account</strong>.</li>
                                </ul>
                            </li>
                        </ol>

                        <p>If you encounter any issues, feel free to contact our support team for help.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}