import Picture from "../../../../../assets/cards/Editing.png";
import {getString} from "../../../../../translations/Strings";

export function FeatureEditing() {
    return (
        <div className="container relative card-style card-border">
            <img src={Picture} alt="card feature editing" className="rounded-[20px]"/>
            <div className="absolute top-8 left-6">
                <p className="card-title-vertical">
                    {getString("landing_features_intuitive_editing_title")}
                </p>
                <p className="card-description-vertical">
                    {getString("landing_features_intuitive_editing_description")}
                </p>
            </div>
        </div>
    )
}