import Picture from "../../../../../assets/cards/Palette.png";
import {getString} from "../../../../../translations/Strings";

export function FeaturePalette() {
    return (
        <div className="container relative card-style card-border">
            <img src={Picture} alt="card feature palette" className="rounded-[20px]"/>
            <div className="w-full absolute top-8 text-center">
                <div className="pr-4 pl-4">
                    <p className="card-title-vertical">
                        {getString("landing_features_palette_title")}
                    </p>
                    <p className="card-description-vertical">
                        {getString("landing_features_palette_description")}
                    </p>
                </div>
            </div>
        </div>
    )
}