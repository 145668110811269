import Picture from "../../../../../assets/cards/Planning.png";
import {getString} from "../../../../../translations/Strings";

export function FeaturePlanning() {
    return (
        <div className="container relative card-style">
            <img src={Picture} alt="card feature planning" className="rounded-[20px]"/>
            <div className="absolute card-content-offset-bottom-left">
                <p className="card-title-horizontal w-1/2">
                    {getString("landing_features_planning_title")}
                </p>
                <p className="card-description-horizontal md:w-2/3">
                    {getString("landing_planning_description")}
                </p>
            </div>
        </div>
    )
}