import React, {useState} from "react";
import {Button} from "@material-tailwind/react";
import axios from "axios";
import {Input, TextArea} from "../../../../inputs/Input";
import {FeedbackResultMessage} from "./FeedbackResultMessage";
import {getString} from "../../../../../translations/Strings";
import {BASE_URL} from "../../../../ApiConstants";

class FeedbackForm {
    email: String
    feedback: String
    constructor(email, feedback) {
        this.email = email;
        this.feedback = feedback;
    }
}


class FeedbackError {
    email: String
    feedback: String
    constructor(email, feedback) {
        this.email = email;
        this.feedback = feedback;
    }
}

class FeedbackResult {
    message: String
    isError: Boolean
    constructor(message) {
        this.message = message;
    }
}

const Feedback = () => {
    const [feedbackForm, setFeedbackForm] = React.useState(new FeedbackForm("", ""));
    const [feedbackError, setFeedbackError] = React.useState(new FeedbackError("", ""));
    const [feedbackResult, setFeedbackResult] = React.useState(new FeedbackResult("", false));
    const [showResult, setShowResult] = useState(false)

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    };

    return (
        <div className='w-full flex flex-col items-center sm:items-start self-center bg-transparent'>
            <p className="text-7 text-center sm:text-start sm:text-10 pb-4 font-medium leading-none">
                {getString("landing_feedback_title")}
            </p>
            <div className="w-full sm:w-5/6 pb-6 pt-6">
                <Input
                    id="email"
                    type="email"
                    value={feedbackForm.email}
                    onChange={
                        (e) => {
                            setFeedbackForm(new FeedbackForm(e.target.value, feedbackForm.feedback))
                        }
                    }
                    label={getString("landing_feedback_email_label")}
                />
            </div>
            <div className="w-full sm:w-5/6 pb-6">
                <TextArea
                    id="feedback"
                    value={feedbackForm.feedback}
                    onChange={
                        (e) => {
                            setFeedbackForm(new FeedbackForm(feedbackForm.email, e.target.value));
                            setFeedbackError(new FeedbackError("", ""));
                        }
                    }
                    error={feedbackError.feedback}
                    label={getString("landing_feedback_feedback_label")}/>
            </div>
            <Button
                disabled={feedbackError.feedback !== "" || feedbackError.email !== ""}
                onClick={
                    () => {
                        if (feedbackForm.feedback === "") {
                            setFeedbackError(new FeedbackError("", getString("landing_feedback_input_error_empty")))
                        } else {
                            axios.post(
                                BASE_URL + "/feedback",
                                {
                                    "email": feedbackForm.email,
                                    "feedback": feedbackForm.feedback
                                },
                                axiosConfig
                            )
                                .then(function (response) {
                                    setFeedbackResult(new FeedbackResult(response.data, false));
                                    console.log(response);
                                })
                                .catch(function (error) {
                                    setFeedbackResult(new FeedbackResult(error.data, true));
                                    console.log(error);
                                }).finally(() => {
                                    setShowResult(true);
                                    setTimeout(() => {
                                        setShowResult(false);
                                    }, 5000
                                    );
                                });
                        }
                    }
                }
                className="bg-white text-black w-full sm:w-[360px] h-[80px] size-fit text-[28px] rounded-[20px]"
            >Submit</Button>
            <FeedbackResultMessage isError={feedbackResult.isError} show={showResult}>{feedbackResult.message}</FeedbackResultMessage>
        </div>
    )
}

export default Feedback