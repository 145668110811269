import React from "react";
import {GridFeatures} from "./features/GridFeatures";
import {CarouselFeatures} from "./features/CarouselFeatures";
import {getString} from "../../../../translations/Strings";

const Features = () => {
    return (
        <div className='w-full pt-10 pb-10 flex flex-col items-center self-center'>
            <p className="text-8 md:text-10 text-center md:text-start font-medium pb-12 leading-none">{getString("landing_features_title")}</p>
            <div class="hidden md:contents">
                <GridFeatures/>
            </div>
            <div class="contents md:hidden">
                <CarouselFeatures/>
            </div>
        </div>
    )
}

export default Features