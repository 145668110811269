import React from "react";
import Menu from "../../../../../assets/icons/menu.svg";
import {
    Drawer,
    IconButton,
} from "@material-tailwind/react";
import LogoCircle from "../../../../LogoCircle";
import {getString} from "../../../../../translations/Strings";

const BurgerMenu = (props) => {
    const [open, setOpen] = React.useState(false);

    const openDrawer = () => setOpen(true);
    const closeDrawer = () => setOpen(false);

    return (
        <React.Fragment>
            <IconButton variant="text" color="white" onClick={openDrawer}>
               <img className="w-6 h-6" src={Menu} alt="menu icon"/>
            </IconButton>
            <Drawer open={open} onClose={closeDrawer} className="p-4 bg-gray_dark">
                <div className="h-full mb-6 flex flex-col justify-between">
                    <div className="mb-6 flex flex-col w-full text-4 text-on-background">
                        <a href="/privacy-policy" className='pr-7 pb-4 pt-8 hover:underline'>{getString("landing_toolbar_privacy_policy")}</a>
                        <a onClick={ () => {
                            closeDrawer()
                            props.scrollToBottom()
                        }} className="hover:underline">Contact us</a>
                    </div>
                    <div className="self-center p">
                        <LogoCircle/>
                    </div>
                </div>
            </Drawer>
        </React.Fragment>
    );
}

export default BurgerMenu