import Picture from "../../../../../assets/cards/Autosaving.png";
import {getString} from "../../../../../translations/Strings";

export function FeatureAutosaving() {
    return (
        <div className="container relative card-style card-border">
            <img src={Picture} alt="card feature autosaving" className="rounded-[20px]"/>
            <div className="absolute card-content-offset-bottom-left">
                <p className="card-title-horizontal w-2/3 md:w-1/2">
                    {getString("landing_features_autosaving_title")}
                </p>
                <p className="card-description-horizontal md:w-1/2">
                    {getString("landing_features_autosaving_description")}
                </p>
            </div>
        </div>
    )
}