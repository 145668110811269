import Feedback from "../feedback/Feedback";
import React from "react";
import {FeatureNoRegistration} from "./FeatureNoRegistration";
import {FeatureEditing} from "./FeatureEditing";
import {FeatureData} from "./FeatureData";
import {FeaturePalette} from "./FeaturePalette";
import {FeaturePlanning} from "./FeaturePlanning";
import {FeatureAutosaving} from "./FeatureAutosaving";
import {FeatureShare} from "./FeatureShare";

export function GridFeatures() {
    return (
        <div className="grid grid-cols-6 gap-8">
            <div className="col-span-6">
                <FeatureNoRegistration/>
            </div>
            <div className="col-span-2">
                <FeatureEditing/>
            </div>
            <div className="col-span-2">
                <FeatureData/>
            </div>
            <div className="col-span-2">
                <FeaturePalette/>
            </div>
            <div className="col-span-6">
                <FeaturePlanning/>
            </div>
            <div className="col-span-6">
                <FeatureAutosaving/>
            </div>
            <div className="col-span-2">
                <FeatureShare/>
            </div>
            <div className="col-span-4">
                <Feedback/>
            </div>
        </div>
    );
}