import Badge from "../assets/google-play-badge.png";

const GooglePlayBadge = () => {
    return (
        <a href="https://play.google.com/store/apps/details?id=com.parcae">
            <img src={Badge} alt="" className="h-[88px] transition ease-in-out duration-300 hover:scale-105"/>
        </a>
    )
}

export default GooglePlayBadge