import React from "react";
import LogoCircle from "../../../../LogoCircle";
import BurgerMenu from "./BurgerMenu";
import {getString} from "../../../../../translations/Strings";

const TopBar = (props) => {
    return (
        <div className='flex justify-between items-center pt-8 pb-12 w-full'>
            <div className='flex items-center w-fit'>
                <LogoCircle/>
                <div class="hidden sm:contents">
                    <div className='pl-4 pb-5 leading-none w-fit'>
                        <p className='text-9 font-medium'>{getString("landing_toolbar_title")}</p>
                        <p className='text-4 font-light'>{getString("landing_toolbar_subtitle")}</p>
                    </div>
                </div>
            </div>
            <div className="flex items-center w-fit">
                <div class="hidden md:contents">
                    <div className="w-fit flex text-4">
                        <a href="/privacy-policy" className="link-style">{getString("landing_toolbar_privacy_policy")}</a>
                        <div className="size-4"></div>
                        <a className="link-style" onClick={props.scrollToBottom}>{getString("landing_toolbar_feedback")}</a>
                    </div>
                </div>
                <div class="contents md:hidden">
                    <BurgerMenu scrollToBottom ={props.scrollToBottom}/>
                </div>
            </div>
        </div>
    )
}

export default TopBar;